import {useEffect} from 'react';
import axios from 'axios';
import { toast} from 'react-toastify';
import { $ }  from 'jquery'

import './style.css';
import { useState } from 'react';

import emailjs from 'emailjs-com';
import sanitizeFileName from '../hooks/regex';

import ReactGA from 'react-ga';

export const FileUploaderTransfer = ({ deleteArray, setDeleteArray, loadingFilesAll, setLoadingFilesAll, cancelToken, filesMoreThanOne, setFilesMoreThanOne, arrayMultiple, setArrayMultiple, onSuccess, setLoaded, name, setFileName, fileName, nextStep, goToStep, setCancelToken, setFileSize, setFileTotal }) => {

    
    const [upload, setUpload] = useState(false);
    
    var files;

    var axiosConfig;

    useEffect(() => {
        files = '' ;
        setFileName('')
    }, [])

    
    const onInputChange = async(e) => {
        files = e.target.files;
        // console.log(e.target.files)
        // console.log(`file`, files)
        if(files !== ''){
            onSubmit(e)
        }
    };

    function formatSizeUnits(bytes){
        if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1)           { bytes = bytes + " bytes"; }
        else if (bytes == 1)          { bytes = bytes + " byte"; }
        else                          { bytes = "0 bytes"; }
        return bytes;
    }

    const cancelRequest = async () => {
        cancelToken.cancel()
        document.getElementById("myForm").reset();
        setFileName('')
        setFilesMoreThanOne(false)
        console.log(arrayMultiple)
        await deleteS3()
        setArrayMultiple([])
        setDeleteArray([])
    }

    const deleteS3 = () =>{
        console.log(deleteArray)
        for (let index = 0; index < deleteArray.length; index++) {
            const element = deleteArray[index];
            console.log(element)
            axios({
                method: 'post',
                url: 'https://ft.yamagroup.com/deleteObj',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                },
                data: {
                  url: element
                }
              })
              .then((response) => {
                // console.log(response);
                // console.log(`DELETED`)
                
              }, (error) => {
                console.log(error);
              });
        }
        document.getElementById("myForm").reset();
        setArrayMultiple([])
        setFilesMoreThanOne(false)

        setLoadingFilesAll(0)
    }

    const onSubmit = async (e) => {
        // e.preventDefault();

        setArrayMultiple([])
        setDeleteArray([])
        setLoaded(0)

        // console.log("FILES", files)
        for(let i = 0; i < files.length; i++) {
            var nameOfFileMultiple = name + '-' + Date.now() + '-' + files[i].name;
            encodeURI(nameOfFileMultiple)
            // console.log("URIIIIIII", nameOfFileMultiple)

            await setArrayMultiple(oldArray => [...oldArray,nameOfFileMultiple ] );
            await setDeleteArray(oldArray => [...oldArray,nameOfFileMultiple] );
            await setFilesMoreThanOne(true)
        }
    };

    const Upload = async() => {
        ReactGA.event({
            category: 'Upload',
            action: 'Uploading', 
            label: '-',
        })


        setLoadingFilesAll(0)

        const selectedFile = document.getElementById('file').files;
        console.log(selectedFile)

        var data = new FormData();

        setArrayMultiple([])
        setDeleteArray([])
        
        for(let i = 0; i < selectedFile.length; i++) {
            var nameOfFileMultiple = name + '-' + Date.now() + '-' + sanitizeFileName(selectedFile[i].name);
            encodeURI(nameOfFileMultiple)

            await setArrayMultiple(oldArray => [...oldArray,nameOfFileMultiple] );
            await setDeleteArray(oldArray => [...oldArray,nameOfFileMultiple] );

            data.append('file', selectedFile[i], nameOfFileMultiple);
        }
        

        var cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource)

        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            onUploadProgress: (ProgressEvent) => {
                const {loaded, total} = ProgressEvent

                const fileSize = formatSizeUnits(loaded)
                const fileTotal = formatSizeUnits(total)
                let percent = Math.floor( (loaded * 100) / total)
                setLoadingFilesAll(percent)
                // setLoaded(percent)
                setFileSize(fileSize)
                setFileTotal(fileTotal)
                // console.log(percent)
                // setLoadingFiles({...loadingFiles, percentFile: percent, nameFile: files[i].name})
            }, 
            cancelToken: cancelTokenSource.token
        };

        nextStep()

        await axios.post('https://ft.yamagroup.com/upload', data, axiosConfig)
        .then(() => {
            toast.success('Upload Success');
            onSuccess(fileName)
        })
        .catch((err) => {
            toast.error('Upload Error')
            if(axios.isCancel(err)){
                // console.log("I WAS CANCELED")
            }
        })
    }

    const Enter = (e) => {
        if(e.keyCode === 13){
          e.preventDefault();
        }
    }

    const sendEmail = async () => {
        console.log(deleteArray)

        var arrayMailHtml = ""

        deleteArray.map(x =>{
            arrayMailHtml = arrayMailHtml + "<a href=https://yama-uploader.s3.amazonaws.com/"+encodeURI(x)+">"+x+"</a><br/> "
            console.log(arrayMailHtml)
        })
        console.log(arrayMailHtml)
        const data = {
          name: name,
          fileName: arrayMailHtml
        }
        
        await emailjs.send('service_dtmicm9', 'template_g3ma1ej', data, 'user_vLr8jShytmqnkQO7rr0yC')
        .then((result) => {
            console.log('success email sent')//This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            ReactGA.event({
                category: 'Sent_Ok',
                action: 'Sent_OK', 
                label: '-',
            })
        }, (error) => {
            // console.log(error.text);
            ReactGA.event({
                category: 'Sent_Error',
                action: 'Sent_Error', 
                label: error.text,
            })
        });
       
        setArrayMultiple([])
        setFilesMoreThanOne(false)
        goToStep(5)
        sendEmail()
    }

    const removeFiles = () => {
        setArrayMultiple([])
        setDeleteArray([])
        setFilesMoreThanOne(false)
        document.getElementById("myForm").reset();
    }

    return (
        <>
            {!filesMoreThanOne ? 
                <form method="post" id="myForm" onSubmit={onSubmit}>
                    <div className="form-group files">
                        <input type="file"
                                id="file"
                            onChange={onInputChange}
                            className="form-control"
                            onKeyDown={(e) => Enter(e)}
                            multiple
                            />
                    </div>
                    {/* <button>Submit</button> */}
                </form>
            : 
                <form method="post" id="myForm" onSubmit={onSubmit} >
                    <div className="form-group files formDisabled">
                        <input type="file"
                                id="file"
                                disabled
                            onChange={onInputChange}
                            className="form-control"
                            accept="video/mp4,video/x-m4v,video/*,video/mov"
                            onKeyDown={(e) => Enter(e)}
                            multiple
                            />
                    </div>
                    {/* <button>Submit</button> */}
                </form>
            }

                <div className="div_files mt-5 mb-3">
                    {arrayMultiple.map( (element, id) => {
                        return ( 
                        <>  
                            <div>
                                <i class="bi bi-file-earmark"><p>{element}</p></i></div>
                            {/* {console.log(loadingFiles)} */}
                        </>)
                    })}
                    {filesMoreThanOne && 
                        (<>
                            <button className="btn-md btn btn-cancel" style={{ width: '45%', marginRight: '5%'}} onClick={(e)=> {removeFiles(); e.preventDefault() }}>CANCEL</button>
                            <button className="btn-md btn btn-next" style={{ width: '45%', marginLeft: '5%'}} onClick={(e)=> {Upload(); e.preventDefault(); setUpload(true) }}>SUBMIT</button>
                        </>)
                    }
                </div>
        </>
    )
};

export default FileUploaderTransfer;