function sanitizeFileName(fileName) {
    console.log('fileName', fileName)
    const name = fileName
    .substring(0, fileName.lastIndexOf('.'))
    .replace(/ /g, '_')
    .replace(/[^a-zA-Z0-9-_]/g, '');  
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    return name + extension;
}

export default sanitizeFileName;