import {useEffect} from 'react';
import axios from 'axios';
import { toast} from 'react-toastify';
import { $ }  from 'jquery'

import './style.css';
import { useState } from 'react';

import emailjs from 'emailjs-com';
import sanitizeFileName from '../hooks/regex';

import ReactGA from 'react-ga';
 
export const FileUploader = ({ 
            loadingFilesAll, 
            setLoadingFilesAll, 
            cancelToken, 
            filesMoreThanOne, 
            setFilesMoreThanOne, 
            arrayMultiple, 
            setArrayMultiple, 
            onSuccess, 
            setLoaded, 
            name, 
            setFileName, 
            fileName, 
            nextStep, 
            goToStep, 
            setCancelToken, 
            setFileSize, 
            setFileTotal
        }) => {
    let files;

    const [deleteArray,setDeleteArray] = useState([]);

    var axiosConfig;

    // window.dataLayer = window.dataLayer || [];

    // function gtag() {
    //     dataLayer.push(arguments);
    // }

    // gtag('js', new Date());

    // gtag('config', 'UA-222100383-1');

    useEffect(() => {
        files = '';
        setFileName('')
    }, [])

    
    const onInputChange = async(e) => {
        // setFiles( e.target.files )
        files =  e.target.files
        // console.log(e.target.files)
        // console.log(`file`, files)
        if(files !== ''){
            onSubmit(e)
        }
    };

    function formatSizeUnits(bytes){
        if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1)           { bytes = bytes + " bytes"; }
        else if (bytes == 1)          { bytes = bytes + " byte"; }
        else                          { bytes = "0 bytes"; }
        return bytes;
    }

    const cancelRequest = async () => {
        cancelToken.cancel()
        document.getElementById("myForm").reset();
        setFileName('')
        setFilesMoreThanOne(false)
        // console.log(arrayMultiple)
        await deleteS3()
        setArrayMultiple([])
        setDeleteArray([])
    }

    const deleteS3 = () =>{ 
        console.log(deleteArray)
        for (let index = 0; index < deleteArray.length; index++) {
            const element = deleteArray[index];
            // console.log(element)
            axios({
                method: 'post',
                url: 'https://ft.yamagroup.com/deleteObj',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                },
                data: {
                  url: element
                }
              })
              .then((response) => {
                // console.log(response);
                // console.log(`DELETED`)
                
              }, (error) => {
                // console.log(error);
              });
        }
        document.getElementById("myForm").reset();
        setArrayMultiple([])
        setFilesMoreThanOne(false)
        setLoadingFilesAll(0)
    }

    const onSubmit = async (e) => {
        ReactGA.event({
            category: 'Upload',
            action: 'Uploading', 
            label: '-',
        })
        e.preventDefault();

        var cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource)

        const data = new FormData();

        if(files.length > 1){
            setArrayMultiple([])
            setDeleteArray([])
            setLoaded(0)

            for(let i = 0; i < files.length; i++) {
                var nameOfFileMultiple = name + '-' + Date.now() + '-' + sanitizeFileName(files[i].name);
                encodeURI(nameOfFileMultiple)

                await setArrayMultiple(oldArray => [...oldArray, nameOfFileMultiple]);
                await setDeleteArray(oldArray => [...oldArray,nameOfFileMultiple] );
                await data.append('file', files[i], nameOfFileMultiple);

                await setFilesMoreThanOne(true)
            }
            
            axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                onUploadProgress: (ProgressEvent) => {
                    const {loaded, total} = ProgressEvent
                    const fileSize = formatSizeUnits(loaded)
                    const fileTotal = formatSizeUnits(total)
                    let percent = Math.floor( (loaded * 100) / total)
                    setLoadingFilesAll(percent)
                    setFileSize(fileSize)
                    setFileTotal(fileTotal)
                    // setLoadingFiles({...loadingFiles, percentFile: percent, nameFile: files[i].name})
                }, 
                cancelToken: cancelTokenSource.token
            };
        } else {
            axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                onUploadProgress: (ProgressEvent) => {
                    const {loaded, total} = ProgressEvent
                    const fileSize = formatSizeUnits(loaded)
                    const fileTotal = formatSizeUnits(total)
                    let percent = Math.floor( (loaded * 100) / total)
                    setLoaded(percent)
                    setFileSize(fileSize)
                    setFileTotal(fileTotal)
                },
                cancelToken: cancelTokenSource.token
            };
            for(let i = 0; i < files.length; i++) {
                
                var nameOfFile = name  + '-' + Date.now() + '-' + files[i].name ;
                encodeURI(nameOfFile)
                
                data.append('file', files[i], nameOfFile);
                // setFileName(nameOfFile)
            }
            nextStep()
            // console.log(axiosConfig)
        }

        await axios.post('https://ft.yamagroup.com/upload', data, axiosConfig)
        .then(() => {
            toast.success('Upload Success');
            onSuccess(fileName)
            
        })
        .catch((err) => {
            toast.error('Upload Error')
            if(axios.isCancel(err)){
                // console.log("I WAS CANCELED")
            }
        })
        setFileName(nameOfFile)
    };

    const Enter = (e) => {
        if(e.keyCode === 13){
          e.preventDefault();
        }
        
    }
    const sendEmail = async () => {
        console.log(deleteArray)

        var arrayMailHtml = ""

        deleteArray.map(x =>{
            arrayMailHtml = arrayMailHtml + "<a href=https://yama-uploader.s3.amazonaws.com/"+encodeURI(x)+">"+x+"</a><br/><br/> "
            // console.log(arrayMailHtml)
        })
        console.log(arrayMailHtml)
        const data = {
          name: name,
          fileName: arrayMailHtml
        }
        
        await emailjs.send('service_dtmicm9', 'template_g3ma1ej', data, 'user_vLr8jShytmqnkQO7rr0yC')
          .then((result) => {
              console.log('success email sent')//This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
          }, (error) => {
            //   console.log(error.text);
          });
       
          setArrayMultiple([])
          setFilesMoreThanOne(false)
          goToStep(5)
      }

    return (
        <>
            {/* { device ? 
            <><p>ESTO ES IOS</p>
                <form method="post" id="myForm" onSubmit={onSubmit}>
                    <div className="form-group files">
                        <input type="file"
                                id="file"
                            onChange={onInputChange}
                            className="form-control"
                            accept=".mp4,.mov"
                            />
                    </div>
                    {/* <button>Submit</button> */
                /* </form> */
                // </>
            // :  */} 
        }

        {!filesMoreThanOne ? 
                <form method="post" id="myForm" onSubmit={onSubmit}>
                    <div className="form-group files">
                        <input type="file"
                                id="file"
                            onChange={onInputChange}
                            className="form-control"
                            accept="video/mp4,video/x-m4v,video/*,video/mov"
                            onKeyDown={(e) => Enter(e)}
                            multiple
                            />
                    </div>
                    {/* <button>Submit</button> */}
                </form>
        : 
        
        <form method="post" id="myForm" onSubmit={onSubmit} >
                <div className="form-group files formDisabled">
                    <input type="file"
                            id="file"
                            disabled
                        onChange={onInputChange}
                        className="form-control"
                        accept="video/mp4,video/x-m4v,video/*,video/mov"
                        onKeyDown={(e) => Enter(e)}
                        multiple
                        />
                </div>
                {/* <button>Submit</button> */}
            </form>
        }
                
                <div className="div_files mt-5 mb-3">
                    {arrayMultiple.map( (element, id) => {
                        return ( 
                        <>  
                            <div>
                                <i class="bi bi-file-earmark"><p>{element}</p></i></div>
                            {/* {console.log(loadingFiles)} */}
                        </>)
                    })}
                   
                   
                {filesMoreThanOne && 
                    (<>
                        <div class="progress mb-4 ">
                            <div class="progress-bar progress-bar-striped progress-bar-animated " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width:`${loadingFilesAll}%`}}></div>
                            <span class="progress-value">{loadingFilesAll}%</span>
                        </div>
                        {loadingFilesAll == 100 ? <button className="btn-md btn btn-cancel" style={{ width: '45%', marginRight: '5%'}} onClick={(e)=> {deleteS3(); e.preventDefault() }}>CANCEL</button> : <button className="btn-md btn btn-cancel" style={{ width: '45%', marginRight: '5%'}} onClick={(e)=> {cancelRequest(); e.preventDefault() }}>CANCEL</button>}
                        {loadingFilesAll == 100 ? <button className="btn-md btn btn-next" style={{ width: '45%', marginLeft: '5%'}} onClick={(e)=> {sendEmail(); e.preventDefault() }}>SUBMIT</button> : <button className="btn-md btn btn-next" style={{ width: '45%', marginLeft: '5%'}} disabled>NEXT</button>}
                    </>)}
                </div>
        </>
    )
};

export default FileUploader;